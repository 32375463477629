import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

// Dodane testowo slajdy z rozdziału pierwszego!!!
// TODO: zmienić na właściwe slajdy
import Slide1 from 'src/slides/desktop/ru/chapter_4/sub_6/slide1';


import WrapperMobile from 'src/slides/mobile/ru/chapter_4/sub_6/wrapperMobile';
import { isMobile } from 'react-device-detect';
// import Slide
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Mobile = () => (
  <>
    <SEO title="Кто был автором плана битвы? I Варшавская битва" lang="ru" description="Самые главные люди, которые способствовали успеху Польши в 1920 году." />
    <WrapperMobile />
  </>
);

const Desktop = () => (
  <>
    <SEO title="Кто был автором плана битвы? I Варшавская битва" lang="ru" description="Самые главные люди, которые способствовали успеху Польши в 1920 году." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
